<template>
    <div class="recharge">
        <van-nav-bar title="我要充值" left-text="返回" left-arrow @click-left="onClickLeft" />
        <div class="system">
            <div class="title">
                <van-divider contentPosition="center" style="color: #07C160; border-color: #07C160; font-size: 18px;">
                    充值面额
                </van-divider>
            </div>
            <div class="content">
                <van-grid column-num="3" border="false" square="true" gutter="15" class="money_content">
                    <van-grid-item icon="balance-o" :text="`${(item.money/100)+' 元'}`"
                       v-for="(item,index) in rechargeCardData" :key="index" @click="toPayCard(item)" />
                </van-grid>
            </div>
        </div>
    </div>
</template>
<script>
import { onBeforeMount, ref } from 'vue';
import request from '../../utils/request';
import { useRouter } from "vue-router";
import { showNotify } from 'vant';
export default {
    setup() {
        const onClickLeft = () => history.back();
        const rechargeCardData = ref([]);
        onBeforeMount(()=>{
            loadRechargeData()
        });
        const loadRechargeData = ()=>{
            request({
                url:'/app/recharge',
                method:'GET'
            }).then((res)=>{
                if(res){
                    rechargeCardData.value=res.items
                }
            })
        };
        const toPayCard = (item)=>{
            request({
                url:'/app/user/recharge_pay',
                method:'POST',
                data:{data:{
                    recharge_id:item.id
                }}
            }).then((res)=>{
                if (res.status && payType == 1) {
                    payParam.value = res.pay_param;
                    if (payMoney.value == 0) {
                        router.push({ name: 'Wallet' })
                    } else {
                        if (typeof WeixinJSBridge == "undefined") {
                            if (document.addEventListener) {
                                document.addEventListener(
                                    "WeixinJSBridgeReady",
                                    onBridgeReady,
                                    false
                                );
                            } else if (document.attachEvent) {
                                document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
                                document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
                            }
                        } else {
                            onBridgeReady();
                        }
                    }
                }
            })
        };
        const payParam = ref('');
        const onBridgeReady = () => {
            WeixinJSBridge.invoke(
                "getBrandWCPayRequest",
                {
                    appId: payParam.value.appId, //公众号ID，由商户传入
                    timeStamp: payParam.value.timeStamp, //时间戳，自1970年以来的秒数
                    nonceStr: payParam.value.nonceStr, //随机串
                    package: payParam.value.package,
                    signType: payParam.value.signType, //微信签名方式：
                    paySign: payParam.value.paySign,
                },
                (res) => {
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                        // 使用以上方式判断前端返回,微信团队郑重提示：
                        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                        router.push({ name: "Wallet" });
                    } else {
                        showNotify({
                            message: '支付失败，请稍后再试',
                        })
                    }
                }
            );
        };
        return {
            rechargeCardData,
            onClickLeft,
            toPayCard,
        }
    },
}
</script>

<style>
.recharge{overflow: hidden;}

.system{}
.system .content{}
.system .content .money_content{}
.system .content .van-grid-item__content{background-color: #07C160;border-radius: 10px;}
.system .content .van-icon{font-size: 40px;color: #ffffff;}
.system .content .van-grid-item__text{font-size: 16px;color: #ffffff;font-weight: bold;}
</style>